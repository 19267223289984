import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from '@emotion/styled';

const PictureBackground = styled(BackgroundImage)`
    height: 600px;
`;

const PictureBackgroundDiv = styled.div`
    color: #FFF;
    height: 100%;
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1{
        font-size: 2rem;
        margin: 0;
        max-width: 800px;
    }

    h2{
        font-size: 1.4rem;
        margin: 0;
        max-width: 500px;
        color: #aaffaa
    }

    @media (min-width: 768px) {
        h1 {
            font-size: 4rem;
        } 
    }
`;

const PhoneDiv = styled.div`
    text-align: center;
    padding-top: 1rem;
    p {
        color: #aaffaa;
        font-size: 1.4rem;
        img {
            padding-right: 1rem;    
        }
    }

    @media (min-width: 768px) {
        p {
            font-size: 2.5rem;
        } 
    }
`;

const Banner = ({banner_h1, banner_h2, phones}) => {

    const { picture, logo, whatsapp } = useStaticQuery( graphql`
        query {
            picture: 
                file(relativePath: {eq: "blonde-girl.jpg"}) {
                    sharp: childImageSharp {
                        fluid( maxWidth: 1200 duotone: { highlight: "#222222", shadow: "#192550", opacity: 30 } ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            logo: 
                file(relativePath: {eq: "banner-logo.png"}) {
                    publicURL
                }
            whatsapp: 
                file(relativePath: {eq: "whatsapp.png"}) {
                    publicURL
                }
        }
    `);

    
    return ( 
        <PictureBackground tag="La celestina" fluid={picture.sharp.fluid} fadeIn="soft" >
           <PictureBackgroundDiv>
                <img src={logo.publicURL} alt="La Celestina" />
                <h1>{banner_h1}</h1>
                <PhoneDiv>
                    <p>
                        <img src={whatsapp.publicURL} alt="La Celestina" />
                        {phones}  
                    </p>
                </PhoneDiv>
                <h2>{banner_h2}</h2>
            </PictureBackgroundDiv>
        </PictureBackground>
    );
}
 
export default Banner;

//