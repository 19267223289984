import { useStaticQuery, graphql } from 'gatsby';

const usePhones = () => {

    const phones = useStaticQuery( graphql `
        {
            allStrapiEntityPhones { 
                nodes { 
                    id
                    phones
                } 
            }
        }
    `);

    return phones.allStrapiEntityPhones.nodes.map( phone => ({
        id: phone.id,
        phones: phone.phones
    }))
}
 
export default usePhones;
